:root {
    --content-color:#FFFFFF;
    --accent-color:#FA5570;
    --content-color-black:#000000;
    --nav-link-bg:#333333;
    --tooltip-color:#757575;
    --black:#000000;
    --white:#ffffff;
    --darkgrey:#484848;
    --grey:#888888;
    --lightgrey:#f2f3f4;
    --red:#D32528;
    --blue:#1E90FF;
    --login_bg_color:#F3F8FF;
    --header_color:#4172B6;
    --subscription_bg_color:#F3F8FF;
    --header_title_color:#4F79B4;
    --cd-font-Family:Lato, sans-serif;
    --strongly-disagree-color:#ff4545;
    --disagree-color:#ffa534;
    --neutral-color:#ffe234;
    --agree-color:#b7dd29;
    --strongly-agree-color:#57e32c;
    --smiley-not-good:/images/not-good.svg;
    --accent-color:#E71D37;
    --accent-color-lighter:#fa5570;
    --accent-color-secondary:#7498fc;
    --login-bg-image:/images/LAObservatory.jpg;
    --cd-label-color:#ffffff;
    --cd-text-color:#ffffff;
    --navbar-color:#FE6236;
    --navbar-text-color:#484848;
    --pages-bg-color:#e6eefa;
    --company-logo:/ObservatisWLogo.png;
    --company-name-logo:/ObservatisWName.png;
    --company-sign-logo:/ObservatisWSign.png;
}

nav {
    margin: 0;
    /* overflow: hidden; */
    /* background: #333333; */
    height:44px !important;
    background-color:transparent;
    transition: 0.3s;
}

.nav-content {
    max-width: 86vw;
    /* 1024px; */
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
}

.nav-content a {
    display: block;
    font-size: 14px;
    font-family: Lato, sans-serif;
    line-height: 44px;
    text-decoration: none;
    transition: all 0.3s;
    color: var(--content-color)!important;
     /* #ffffff; */
}

.nav-content label {
    display: block;
    font-size: 16px;
    font-family: Lato, sans-serif;
    line-height: 44px;
    text-decoration: none;
    transition: all 0.3s;
    color: var(--accent-color)!important;
     /* #fa5570; */
}

.nav-content button {
    display: block;
    font-size: 16px;
    font-family: Lato, sans-serif;
    line-height: 44px;
    text-decoration: none;
    transition: all 0.3s;
    color: var(--accent-color)!important;
     /* #fa5570; */
}

.nav-content span {
    display: block;
    font-size: 22px;
    font-family: Lato, sans-serif;
    line-height: 44px;
    text-decoration: none;
    transition: all 0.3s;
    color: var(--content-color)!important;
    /* #ffffff; */
}

.nav-content a:hover,
.nav-icon:hover,
.search-icon:hover {
    /* opacity: 0.7!important; */
    color: var(--accent-color)!important;
    cursor: pointer!important;
}

.nav-links {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav-icon {
    display: none;
    grid-gap: 5px;
    grid-template-columns: auto;
    padding: 17px 0;
    height: 10px;
    cursor: pointer;
    transition: all 0.3s;
}

.bar {
    height: 1px;
    width: 18px;
    background: var(--content-color);
    /* white; */
    transition: 0.5s;
}

.search-icon {
    cursor: pointer;
    transition: all 0.3s;
}

@media (max-width: 768px) {
    .show {
        background-color: var(--content-color-black);
        /* #000000; */
    }

    .nav-links {
        position: fixed;
        top: 44px;
        right: 0;
        height: 0;
        width: 100%;
        background: var(--nav-link-bg);
        /* #333333; */
        flex-direction: column;
        justify-content: flex-start;
        transition: height 2s cubic-bezier(0.19, 1, 0.22, 1),
            background-color 0.3s;
    }

    .show .nav-links {
        height: 100%;
        background-color: var(--content-color-black);
        /* #000000; */
    }

    .nav-links a {
        height: 0;
        width: 0;
        opacity: 0;
        overflow: hidden;
        margin-right: 50px;
        margin-left: 50px;
        transition: opacity 1.5s, height 2s;
    }

    .show .nav-links a {
        opacity: 1;
        width: auto;
        height: auto;
    }

    .nav-icon {
        order: 1;
        display: grid;
    }

    .logo {
        order: 2;
    }

    .search-icon {
        order: 3;
    }
}

.show .one {
    transform: rotate(45deg) translateY(5.5px);
}

.show .two {
    transform: rotate(-45deg) translateY(-5.5px);
}

.content {
    max-width: 1024px;
    min-height: 380px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.custom-tooltip {
    position: relative;
    display: inline-flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-radius: 8px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: var(--tooltip-color);
    /* #757575; */
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(18, 18, 18, 0.25);
    opacity: 1;
  }
  .custom-tooltip:before {
    content: "";
    position: absolute;
    top: 60px;
    left: -15px;
    border: solid 8px transparent;
    border-right-color: #ffffff;
    z-index: 1;
  }
  .bar {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background: #fff;
  }
  .outer{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    /* color: rgb(191, 210, 210); */
  }
  .custom-slider {
    /* width: 70%; 
    margin: 0 auto; */
    color:  var(--accent-color-secondary);
  }
  .dropdown-search {
    /* height: 23px!important; */
    border-radius: 6px;
    background-color:  var(--grey)!important;
  }
  .dropdown-search-transparent {
    /* height: 23px!important; */
    border-radius: 6px;
    border-width:0px!important;
    border-color: var(--pages-bg-color)!important;
    background-color:transparent!important;
}
  .dropdown-search-item-selected {
    background-color:  var(--pages-bg-color)!important;
  }
  .dropdown-search-item-unselected {
    background-color:  var(--light-grey)!important;
  }
  .mainnav-primary-item-selected {
    display: block;
    font-size: 16px;
    font-family: Lato, sans-serif;
    line-height: 44px;
    text-decoration: none;
    transition: all 0.3s;
    color: var(--accent-color)!important;
     /* #fa5570; */
}
.mainnav-primary-item-selected>a::after {
    color: var(--accent-color)!important;
}
.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
}
.editor-class {
    background-color:rgb(244, 244, 244);
    padding: 1rem;
    border: 1px solid #ccc;
}
.toolbar-class {
    border: 1px solid #ccc;
}
.rdw-dropdown-wrapper {
    height: 30px!important;
    background: white!important;
    cursor: pointer;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    margin: 0 3px;
    text-transform: capitalize;
    background: white;
    }
    
    .rdw-dropdown-selectedtext {
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    padding: 0 5px;
    }
    
    .rdw-dropdown-carettoclose {
      height: 0px;
      width: 0px;
      position: absolute;
      top: 35%;
      right: 10%;
      border-bottom: 6px solid black;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
    
    .rdw-dropdown-carettoopen {
      height: 0px;
      width: 0px;
      position: absolute;
      top: 35%;
      right: 10%;
      border-top: 6px solid black;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
    
    .rdw-dropdown-optionwrapper {
    z-index: 100;
    position: relative;
    border: 1px solid #f1f1f1;
    width: 98%;
    background: white;
    border-radius: 2px;
    margin: 0;
    padding: 0;
    max-height: 250px;
    overflow-y: scroll;
    }
    
    .rdw-dropdownoption-active {
      background: #f5f5f5;
    }
    
    .rdw-dropdownoption-default {
      min-height: 25px;
      display: flex;
      align-items: center;
      padding: 0 5px;
    }
    