.hidden {
    transition: all 1s ease-in-out;
    transform-origin: left top;
    transform: scaleY(0);
}
   
.visible {
    transform: scaleY(1);
}

.sv-btn.sv-footer__next-btn.my-button {
  /* background-color: #E71D37; */
}

.sv-btn sv-footer__complete-btn.my-button {
  background-color: #E71D37;
}

.sv-btn sv-footer__prev-btn  my-button {
  background-color: #E71D37;
}
.sv-btn sv-footer__start-btn  my-button {
  background-color: #E71D37;
}
.sv-btn sv-footer__preview-btn  my-button {
  background-color: #E71D37;
}
.sv-btn sv-footer__edit-btn my-button {
  background-color: #E71D37;
}
.sv-btn nav-button {
  background-color: #E71D37;
}
.nav-button {
  margin-left: auto; 
  margin-right: 0;
}

.nav-input {
  color: #ffffff;
  background-color: #ff9814;
}

.nav-input:hover {
  background-color: #f28a05;
}